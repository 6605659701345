import React, { useState, useEffect } from 'react'
import logo from './images/logo.svg'
import avatar from './images/avatar-profile.png'
import './App.css'

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement("script");
		script.src = src;
		script.onload = () => {
			resolve(true);
		};
		script.onerror = () => {
			resolve(false);
		};
		document.body.appendChild(script);
	});
}

const __DEV__ = document.domain === "localhost";

function App() {
	const baseUrl = "https://adhyandigital.com/api/";
	const imgUrl = "https://adhyandigital.com/api";
	// const baseUrl = "http://192.168.0.124:5500/";
	// const imgUrl = "http://192.168.0.124:5500";
	const [userData, setUserData] = useState({
		personDetails: {},
		orderId: 0,
		device:0,
		orderDetails: {},
		batchDaysCount: {}
	});

	useEffect(() => {
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		const value = params.orderId ?? null;
		const deviceValue = params.device ?? null;
		console.log("deviceValue", deviceValue);
		setUserData({
			personDetails: {},
			orderId: value,
			device: deviceValue,
			orderDetails: {},
			batchDaysCount: {}
		});

		const getOrderDetails = async () => {
			const orderData = await fetch(`${baseUrl}subscribe/fetchorder`, 
				{ 
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({orderId: value})
				}).then((t) =>
				t.json()
			)
			setUserData({
				personDetails: orderData.data[0].personalDetails,
				orderId: value ?? 0,
				device: deviceValue ?? 0,
				orderDetails: orderData.data[0],
				batchDaysCount: orderData.data[0].batchDaysCount
			})
		}
		getOrderDetails();
	},[]);


	const updateRazorpay = async (razorPayId) => {
		const updateData = await fetch(`${baseUrl}subscribe/razorpayidupdate`, 
				{ 
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({orderId: userData.orderId, rzTransId: razorPayId})
				}).then((t) =>
				t.json()
			)
		return updateData;
	};
	let teacherNameDisplay = "";
	let tname = userData.orderDetails.display_name;
	if(tname ==null){
		teacherNameDisplay = (userData.orderDetails.teacherFName)+" "+(userData.orderDetails.teacherLName)
	}
	else{
		teacherNameDisplay = (userData.orderDetails.display_name);
	}
	let teacherImg = "";
	if(userData.orderDetails.teacherProfilePic === null) {
       teacherImg = avatar;
    } else {
		teacherImg = imgUrl+userData.orderDetails.teacherProfilePic;
    }
	async function displayRazorpay() {
		const res = await loadScript(
			"https://checkout.razorpay.com/v1/checkout.js"
		);

		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}

		const data = await fetch(`${baseUrl}paymentDetails/razorpay`, { 
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({orderId: userData.orderId})
		}).then((t) =>
			t.json()
		)

		//console.log(data);
		let options = {};
if(userData.device == 2){  //FOR PWA
	options = {
		key: __DEV__ ? "rzp_test_psjiIxrlswGdfo" : "rzp_test_psjiIxrlswGdfo",
		currency: data.data.currency,
		amount: data.data.amount.toString(),
		order_id: data.data.id,
		name: "Payment",
		description: "Thank you for choosing intuition, start making payment",
		// image: "http://localhost:1337/logo.svg",
		image: "https://intuitionindia.in/wapp/assets/images/logo.svg",
		handler: async function (response) {
			await updateRazorpay(response.razorpay_payment_id);
			alert("Your payment successfully");
			// window.location.href="http://192.168.0.124/intuition-pwa/admin/student/dashboard.html";
			window.location.href="https://m.intuitionindia.in/admin/student/dashboard.html";
			// alert(response.razorpay_payment_id);
			// alert(response.razorpay_order_id);
			// alert(response.razorpay_signature);
		},
		prefill: {
			name: userData.personDetails.first_name + userData.personDetails.last_name, //userData.first_name,
			email: userData.personDetails.email_id, //userData.email_id,
			phone_number:  userData.personDetails.contact_no, //userData.contact_no
		}
	}
}
else if(userData.device == 1){  //FOR WEB
	options = {
		key: __DEV__ ? "rzp_test_psjiIxrlswGdfo" : "rzp_test_psjiIxrlswGdfo",
		currency: data.data.currency,
		amount: data.data.amount.toString(),
		order_id: data.data.id,
		name: "Payment",
		description: "Thank you for choosing intuition, start making payment",
		// image: "http://localhost:1337/logo.svg",
		image: "https://intuitionindia.in/wapp/assets/images/logo.svg",
		handler: async function (response) {
			await updateRazorpay(response.razorpay_payment_id);
			alert("Your payment successfully");
			// window.location.href="http://192.168.0.124/intuition-web/admin/tutor/dashboard.html";
			window.location.href="https://intuitionindia.in/wapp/admin/student/dashboard.html";
			// alert(response.razorpay_payment_id);
			// alert(response.razorpay_order_id);
			// alert(response.razorpay_signature);
		},
		prefill: {
			name: userData.personDetails.first_name + userData.personDetails.last_name, //userData.first_name,
			email: userData.personDetails.email_id, //userData.email_id,
			phone_number:  userData.personDetails.contact_no, //userData.contact_no
		}
	}
}
		// const options = {
		// 	key: __DEV__ ? "rzp_test_psjiIxrlswGdfo" : "rzp_test_psjiIxrlswGdfo",
		// 	currency: data.data.currency,
		// 	amount: data.data.amount.toString(),
		// 	order_id: data.data.id,
		// 	name: "Payment",
		// 	description: "Thank you for choosing intuition, start making payment",
		// 	// image: "http://localhost:1337/logo.svg",
		// 	image: "https://intuitionindia.in/webapp/assets/images/logo.svg",
		// 	handler: async function (response) {
		// 		await updateRazorpay(response.razorpay_payment_id);
		// 		alert("Your payment successfully");
		// 		window.location.href="http://192.168.0.124/intuition-pwa/admin/student/dashboard.html";
		// 		// window.location.href="https://m.intuitionindia.in/admin/student/dashboard.html";
		// 		// alert(response.razorpay_payment_id);
		// 		// alert(response.razorpay_order_id);
		// 		// alert(response.razorpay_signature);
		// 	},
		// 	prefill: {
		// 		name: userData.personDetails.first_name + userData.personDetails.last_name, //userData.first_name,
		// 		email: userData.personDetails.email_id, //userData.email_id,
		// 		phone_number:  userData.personDetails.contact_no, //userData.contact_no
		// 	}
		// }
		const paymentObject = new window.Razorpay(options)
		paymentObject.open()
	}
	
	return (
		<div className="flex justify-center items-center h-screen">
			<div className="card">
				<div className="card-content">
					<aside className="flex items-center justify-center gap-2 pb-6 clearfix">
						<img src={logo} className="logo" alt="Intuition" />
						<span className="h3 text-blue">Intuition</span>
					</aside>

					<aside class="flex items-center gap-2">
						<img src={teacherImg}  alt="" className="avatar" />
						<span class="h5 text-gray">{teacherNameDisplay}</span>
					</aside>

					<ul className="list-unstyled text-gray">
						<li>
							<span>Batch Name: </span>
							<span>{userData.orderDetails.batchName}</span>
						</li>
						{/* <li>
							<span>Days: </span>
							{userData.batchDaysCount.map((item,key)=>{
							<span>{item.dayName}</span> })};
						</li> */}
						<li>
							<span>Tenure: </span>
							<span>{userData.orderDetails.tenure}</span> <span>months</span>
						</li>
						<li>
							<span>Price: </span>
							<span>&#8377;</span>
							<span>{userData.orderDetails.perMonth}</span>
							<span>/month</span>
						</li>
						<li>
							<span>Start Date: </span>
							<span>{userData.orderDetails.start_date}</span>
						</li>
						<li>
							<span>End Date: </span>
							<span>{userData.orderDetails.end_date}</span>
						</li>
						<li>
							<span>Email Id: </span>
							<span>{userData.personDetails.email_id}</span>
						</li>
						<li>
							<span>Contact No.: </span>
							<span>{userData.orderDetails.teacherContact_no}</span>
						</li>
					</ul>

					<aside className="h4 font-medium border-dashed-top border-dashed-bottom text-center py-2 mb-4">
						<span className="text-lightGray">Amount Payable: </span>
						<span className="text-green">&#8377;</span>
						<span className="text-green">{userData.orderDetails.net_amount}</span>
					</aside>

					<aside className="text-center">
						<button
							type="button"
							className="btn btnPay"
							onClick={displayRazorpay}
							target="_blank"
							rel="noopener noreferrer"
						>
							Pay Now!
						</button>
					</aside>
				</div>
			</div>
		</div>
	);
}

export default App;
